import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function PrivacyPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Contact"
      />
      <section className="p-16">
      <h1><strong>Datenerhebung und Speicherung / Nutzung und Weitergabe</strong></h1>
      <p>Ja, auch wir erheben Ihre Daten. Ja, diese sind tatsächlich personenbezogen und das hat einen guten Grund: Wenn Sie Reiseanfragen stellen oder Buchungen bei uns vornehmen, behandeln wir Sie wie Sie es verdienen - wie einen geschätzten Kunden. Ihre Daten helfen uns und Ihnen, Angebote zuzuordnen und Verträge zu schließen. Wenn Sie eine Buchung vornehmen, geben Sie dafür personenbezogene Daten preis, das sind insbesondere Ihre Buchungs- und Kontaktdaten evtl. auch für andere Reisende (Name, Vorname, Adresse, E-Mail, Telefonnummer), ggfs. die Daten in Reisedokumenten (Passnummer, Passdaten, Geburtsdatum,) und ggf. Daten im Zusammenhang mit Zahlungen. Evtl. weitere von Ihnen angegebene Daten zur Erfüllung unser vertraglichen Pflichten und Abwicklung Ihrer Reise speichern und verarbeiten wir und, soweit dies für die Buchung wichtig ist, geben wir sie auch an Dritte weiter, z. B. an Leistungsträger wie Reiseveranstalter, Hotels, Airlines, Mietwagenleihfirmen, Veranstalter, Ticketverkäufer oder niedergelassene Agenturen in Ihrem Zielgebiet, sog. DMCs, die uns helfen, für Sie den besten Service für Ihre Unternehmung zu bieten. Für diesen Service müssen Sie nicht einmal eine gesonderte Einwilligung unterschreiben, denn wir bemühen uns stets, den Aufwand für alle Beteiligten überschaubar zu halten.</p>
      <p>Eine Nutzung und Weitergabe Ihrer Daten, z.B. für Zwecke der Werbung und Marktforschung erfolgt dagegen nur, wenn Sie hierzu zuvor Ihre Einwilligung erteilt haben. Ihre jeweilige Einwilligung diesbezüglich können Sie selbstverständlich jederzeit mit Wirkung für die Zukunft widerrufen. Bitte senden Sie dazu eine E-Mail mit dem Betreff &quot;Daten für Werbung löschen&quot;. Die Verarbeitung und Nutzung Ihrer personenbezogenen Daten erfolgt nach den Vorgaben des BDSG bzw., ab 25.05.2018, der EU-DSGVO.</p>
      <p>Sie können sich immer mit mir, Diana Naumann, in Verbindung setzten, um ihre persönlichen Daten, die bei uns gespeichert sind, abzurufen</p>
      <p>Ungekürzt heißt das:</p>
      <p><strong>DATENSCHUTZHINWEIS</strong></p>
      <p>Ein verantwortungsvoller Umgang mit den personenbezogenen Daten, die Sie mit uns teilen, ist ein wichtiger Teil unseres Geschäftsgebarens. Ihre Daten sind bei uns sicher aufgehoben.</p>
      <p>Der Verantwortliche ist DIANA NAUMANN ~ Easy Travel Experts (in diesem Datenschutzhinweis als &quot;wir&quot; oder &quot;uns&quot; bezeichnet). Wir haben uns bemüht, diesen Hinweis verständlich zu gestalten.</p>
      <p><strong>Dieser Datenschutzhinweis erklärt Ihnen:</strong></p>
      <ul>
      <li>welche Arten personenbezogener Daten wir erheben und warum wir das tun</li>
      <li>wann und wie wir personenbezogene Daten mit anderen Organisationen teilen</li>
      <li>Ihre Möglichkeiten, wie Sie Ihre personenbezogenen Daten abrufen und aktualisieren können.</li>
      </ul>
      <p><strong>Personenbezogene Daten, die wir erheben</strong></p>
      <p>Wenn Sie sich für unsere Dienstleistungen interessieren, übermitteln Sie uns ggf. von sich aus:</p>
      <ul>
      <li>Ihre Personalien, wie z. B. Name, Anschrift, E-Mail-Adresse, Telefonnummer und Ihr Geburtsdatum</li>
      </ul>
      <p>Wenn Sie Reisen oder andere Produkte aus unseren Angeboten buchen, erheben wir Daten für die Erfüllung Ihres Vertrages mit uns:</p>
      <ul>
      <li>Ihre Personalien, wie z. B. Name, Anschrift, E-Mail-Adresse, Telefonnummer und Ihr Geburtsdatum</li>
      <li>Evtl. Passdaten und Angaben über besondere Bedürfnisse wie körperliche Einschränkungen etc.</li>
      <li>die Personalien Ihrer Mitreisenden</li>
      <li>Zahlungsdaten (z. B. Kreditkartendaten, Kontoinformationen, Rechnungsanschrift)</li>
      <li>Ihre gebuchten Produkte oder Dienstleistungen.</li>
      </ul>
      <p>Wenn Sie auf unseren Webseiten surfen oder unsere mobilen Apps benutzen, erheben wir möglicherweise:</p>
      <ul>
      <li>Reisevorlieben</li>
      <li>Informationen über Ihr Surfverhalten auf unserer Webseite und mobilen App</li>
      <li>Informationen darüber, wann Sie auf eine unserer Werbungen klicken (auch wenn diese auf anderen Webseiten eingebunden sind)</li>
      <li>Informationen über die Art und Weise, wie Sie unsere digitalen Dienste abrufen, einschließlich Betriebssystem, IP-Adresse, Online-Identifizierungsmerkmale und Browser-Details</li>
      <li>soziale Präferenzen, Interessen und Aktivitäten.</li>
      </ul>
      <p>Wenn Sie unsere Produkte in unseren Shops oder online kaufen, erheben wir möglicherweise:</p>
      <ul>
      <li>Informationen über Reisende, Passdaten, andere Ausweisinformationen</li>
      <li>Versicherungsdaten (wichtig für Ihre Reiseabsicherung)</li>
      <li>relevante medizinische Daten sowie etwaige besondere Diätwünsche oder sonstige Anforderungen aus religiösen Gründen und Gründen körperlicher Beeinträchtigung (damit Sie sicher unterwegs sind und gut versorgt werden)</li>
      <li>Informationen über Ihre Käufe, z. B. was Sie kauften, wann und wo Sie es kauften, wie Sie bezahlt haben sowie Kredit- oder andere Zahlungsinformationen (damit Sie die Sicherheit haben, dass Sie Ihre gebuchten Leistungen auch bekommen)</li>
      <li>Informationen über Ihr Surfverhalten auf unserer Webseite und mobilen App (damit machen wir diese Tools immer übersichtlicher)</li>
      <li>Informationen darüber, wann Sie auf eine unserer Werbungen klicken (damit Sie statt langweiligen Dingen möglichst relevante Inhalte gezeigt bekommen)</li>
      <li>Informationen über die Art und Weise, wie Sie unsere digitalen Dienste abrufen, einschließlich Betriebssystem, IP-Adresse, Online-Identifizierungsmerkmale und Browser-Details (damit Sie unsere Leistungen fehlerfrei und sicher auch online recherchieren uns buchen können)</li>
      <li>soziale Präferenzen, Interessen und Aktivitäten (damit wir unser Angebot für Sie stets interessant halten und weiterentwickeln können)</li>
      </ul>
      <p>Wenn Sie uns oder wir Sie kontaktieren oder wenn Sie an Werbeaktionen, Wettbewerben, Umfragen über unsere Dienstleistungen teilnehmen, erheben wir möglicherweise:</p>
      <ul>
      <li>Personalien, die Sie übermitteln, wenn Sie sich mit uns in Verbindung setzen, einschließlich E-Mail, Post und Telefon oder über soziale Medien, wie z. B. Ihren Namen, Benutzernamen und Ihre Kontaktdaten</li>
      <li>Details über E-Mails und andere digitale Kommunikation, die wir an Sie senden und die Sie öffnen, einschließlich darin enthaltener Links, die Sie anklicken</li>
      <li>Ihr Feedback und Ihre Beiträge zu Kundenumfragen.</li>
      </ul>
      <p><strong>Andere Quellen personenbezogener Daten</strong></p>
      <p>Wir dürfen personenbezogene Daten aus anderen Quellen verwenden, z. B. von Unternehmen, die Informationen und Daten bereitstellen, Handelspartnern und aus öffentlichen Registern.</p>
      <p>Ihre Versicherungsgesellschaft, deren Vertreter und medizinische Angestellte dürfen relevante personenbezogene Daten und besondere Kategorien von personenbezogenen Daten mit uns austauschen, und zwar unter Umständen, unter denen wir/sie in Ihrem Namen oder im Interesse anderer Kunden oder in einem Notfall handeln müssen.</p>
      <p>Wenn Sie sich mit Ihren Zugangsdaten eines sozialen Netzwerks einloggen, um sich mit unseren Plattformen und Online-Diensten zu verbinden, willigen Sie ein, Ihre Benutzerdaten mit uns zu teilen. Beispielsweise Ihren Namen, Ihre E-Mail-Adresse, Ihr Geburtsdatum, Ihren Standort und andere Informationen, die Sie mit uns teilen möchten.</p>
      <p>Wir dürfen Aufzeichnungen von Überwachungskameras, IP-Adressen und Browserdaten verwenden, die in oder in unmittelbarer Nähe unserer Shops, Geschäftsräume, sonstiger Gebäude sowie Kreuzfahrtschiffe erhoben wurden.</p>
      <p><strong>Personenbezogene Daten über andere Personen, die Sie uns übermitteln</strong></p>
      <p>Wir verwenden von Ihnen übermittelte personenbezogene Daten über andere Personen, z. B. weitere Informationen auf Ihrer Buchung.</p>
      <p>Indem Sie personenbezogene Daten anderer Personen übermitteln, müssen Sie sicher sein, dass diese damit einverstanden sind und Sie die Daten übermitteln dürfen. Auch sollten Sie gegebenenfalls dafür sorgen, dass diese Personen wissen, wie ihre personenbezogenen Daten von uns verwendet werden könnten.</p>
      <p><strong>Verwendung Ihrer personenbezogenen Daten</strong></p>
      <p>Wir verwenden Ihre personenbezogenen Daten, wie nachstehend erläutert, auf vielfältige Weise.</p>
      <p>Um Produkte und Dienstleistungen bereitzustellen, die Sie anfordern, müssen wir Ihre personenbezogenen Daten verarbeiten, damit wir Ihren Account oder Ihre Buchung verwalten, Ihnen die gewünschten Produkte und Dienstleistungen bereitstellen und Ihnen bei Bestellungen und evtl. verlangten Rückerstattungen helfen zu können. Wir verwenden personenbezogene Daten, um unsere Produkte, Webseite, mobile App und andere Dienstleistungen zu verwalten und zu verbessern. Wir dokumentieren, wie unsere Dienstleistungen genutzt werden, um Ihre personenbezogenen Daten zu schützen sowie Betrugsfälle, andere Straftaten und den Missbrauch von Dienstleistungen zu erkennen und zu verhindern. Dies hilft uns sicherzustellen, dass unsere Dienstleistungen für Sie sicher sind.</p>
      <p>Personenbezogene Daten dürfen wir auch verwenden, um auf Sicherheitsvorfälle, Störungen oder andere ähnliche Vorkommnisse zu reagieren und sie zu verwalten. Dies kann u. a. medizinischer oder auch versicherungsbezogener Natur sein, um Marktforschung zu betreiben und um unsere Produktpalette, Dienstleistungen, Shops und IT-Systeme, Sicherheit, Kommunikation mit Ihnen zu entwickeln und zu verbessern.</p>
      <p><strong>Kontaktaufnahme und Interaktion</strong></p>
      <p>Wir wollen Sie als Kunden gut betreuen. Wenn Sie uns also kontaktieren, egal ob persönlich, postalisch, über Telemedien oder digital, dürfen wir personenbezogene Daten verwenden, um Ihre Wünsche zu erfüllen. Wir verarbeiten Ihre personenbezogenen Daten möglicherweise, um Werbeaktionen und Wettbewerbe veranstalten zu können, an denen Sie freiwillig teilnehmen dürfen. Darunter ggf. auch solche, die wir zusammen mit unseren Handelspartnern organisieren.</p>
      <p>Wir freuen uns, wenn Sie an Kundenumfragen und sonstigen Marktforschungsaktivitäten von Easy Travel Experts teilnehmen, denn damit helfen Sie uns, Sie als Kunde zu verstehen und Ihnen Dienstleistungen und Kommunikationswege zu eröffnen. Dafür dürfen wir Ihre personenbezogenen Daten, die wir erheben, wenn Sie Käufe in einem Shop tätigen, mit personenbezogenen Daten kombinieren, die über unsere Webseite, mobile App oder andere Quellen erhoben wurden.</p>
      <p>Wir veräußern Ihre personenbezogenen Daten nicht an Dritte.</p>
      <p><strong>Marketingmaßnahmen mit Ihrer Einwilligung</strong></p>
      <p>Von Zeit zu Zeit bieten wir Ihnen an, Ihnen Angebote und News über unsere Produkte und Dienstleistungen zukommen lassen. Wir werden dies nur dann tun, wenn Sie zuvor Ihr Einverständnis dazu erklärt haben. Wenn Sie sich an uns wenden, werden wir Sie fragen, ob und über welchen Kanal Sie Marketing-Kommunikation erhalten möchten. Allerdings haben Sie keinen Anspruch darauf, dass wir Ihre Informationswünsche immer umgehend und über alle Kanäle bedienen. Sie wählen einfach aus den angebotenen Kommunikationswegen aus, z. B. Postversand, E-Mail Newsletter, Telefon, TikTok, Instagram, Messenger etc. und wir werden uns an Ihre Auswahl halten. Wir fragen Sie gegebenenfalls, ob Sie auch Marketing-Kommunikation von anderen Unternehmen erhalten möchten und wir die Daten hierzu an die anderen Unternehmen weitergeben dürfen.</p>
      <p>Online mitgeteilten Kontaktdaten zur Marketinginformation lassen wir uns zusätzlich noch einmal bestätigen. Das heißt, wir senden Ihnen im Fall eines E-Mail Newsletters erst dann E-Mail Marketing Kommunikation zu, wenn Sie den zugesandten Link aktivieren. Bei Werbung via SMS oder Messenger handhaben wir das in ähnlicher Weise.</p>
      <p>Sie können Ihre erteilte Einwilligung für Marketing-Kommunikation jederzeit widerrufen, ob online, am Telefon, per Link zum Abbestellen von z. B. Newslettern in unseren Marketing-Emails, mit der Antwort HALT zur Kurzwahlnummer in unseren Marketing-SMS oder indem Sie uns schreiben (z. B. per E-Mail an easy.travel@email.de). Sie können Ihre Einwilligung ganz oder teilweise widerrufen. Möchten Sie keine Werbung mehr über einen bestimmten Kanal (z. B. Telefon) erhalten, können Sie uns dies ebenso mitteilen wie einen Widerruf für sämtliche erteilten Einwilligungen für Marketingzwecke. Trotz des Wiederrufs für Marketingzwecke erhalten Sie natürlich dienstleistungsbezogene Benachrichtigungen von uns, wie Buchungsbestätigungen und andere wichtige Informationen über unsere Produkte und Dienstleistungen (z.B. Flugverschiebungen).</p>
      <p><strong>Ihr persönliches Erlebnis mit uns</strong></p>
      <p>Wir wollen bewirken, dass unsere Marketing-Kommunikation auf Ihre Interessen zugeschnitten ist. Hierfür verwenden wir die von Ihnen übermittelten und automatisch generierten personenbezogenen Daten, um Ihre Interessen besser zu erkennen. Wir nutzen hierfür vorhandene Informationen, wie beispielsweise Empfangs- und Lesebestätigungen von E-Mails, Informationen über Computer und Verbindung zum Internet, Betriebssystem und Plattform, Ihre Bestellhistorie, Ihre Servicehistorie, Datum und Zeit des Besuchs der Homepage, Produkte, die Sie angeschaut haben. Diese Informationen nutzen wir ausschließlich in pseudonymisierter Form. Unser Ziel ist es, unsere Werbung so für Sie informativer und interessanter zu gestalten.</p>
      <p>Wenn Sie keinen personalisierten Service z. B. einen personalisierten Newsletter von uns erhalten wollen, können Sie der Personalisierung Ihrer Werbung uns gegenüber jederzeit z. B. telefonisch, schriftlich oder per E-Mail an easy.travel@email.de widersprechen. In diesem Fall erhalten Sie unangepasste Standard-Werbung. Sie können natürlich auch der Marketing-Kommunikation durch uns jederzeit als Ganzes widersprechen bzw. Ihre Einwilligung widerrufen. Wir werden Ihren Wunsch berücksichtigen und Ihre Daten so bald wie möglich aktualisieren.</p>
      <p><strong>Marktforschung</strong></p>
      <p>Ihre Meinung ist uns wichtig. Um unsere Produkte und Dienstleistungen zu verbessern, werden wir Sie eventuell zu Marktforschungszwecken kontaktieren. Sie haben stets die Wahl, ob Sie an unserer Marktforschung teilnehmen möchten.</p>
      <p><strong>Teilen personenbezogener Daten mit Handelspartnern</strong></p>
      <p>Um von Ihnen gewünschte Produkte und Dienstleistungen bereitzustellen, müssen wir personenbezogene Daten Ihrer gebuchten Reiseleistungen mit Anbietern teilen, darunter Fluggesellschaften, Hotels und Transportunternehmen etc. Wir arbeiten auch mit sorgfältig ausgewählten Anbietern zusammen, die bestimmte Funktionen für Sie ausführen. Beispielsweise mit Unternehmen, die uns bei IT-Dienstleistungen, Datenspeicherung und -verknüpfung, Marketing, Marktforschung, Bearbeitung von Zahlungsvorgängen sowie bei der Bereitstellung von Produkten und Dienstleistungen unterstützen. Wenn wir personenbezogene Daten mit anderen Organisationen teilen, verlangen wir von diesen, die Daten sicher aufzubewahren. Sie dürfen Ihre personenbezogenen Daten nicht für eigene Marketingzwecke verwenden. Wir teilen nur das Minimum personenbezogener Daten, das unseren Handelspartnern ermöglicht, ihre Dienstleistungen für Sie und uns zu erbringen.</p>
      <p><strong>Teilen personenbezogener Daten mit Behörden und anderen</strong></p>
      <p>Wir dürfen das notwendige Minimum an personenbezogenen Daten mit anderen Behörden teilen, falls das Gesetz uns hierzu verpflichtet oder dies uns rechtlich erlaubt ist.</p>
      <p>Um unsere gesetzlichen Rechte zu wahren oder zu verteidigen, müssen wir evtl. Ihre personenbezogenen Daten teilen; hierzu gehört die Übermittlung personenbezogener Daten an Dritte, um z. B. Betrugsdelikte zu verhindern und Zahlungsausfallrisiken zu reduzieren.</p>
      <p>Damit Sie reisen können, ist es zum Teil gesetzlich vorgeschrieben durch Behörden am jeweiligen Ausgangs- und/oder Bestimmungsort Ihre personenbezogenen Daten für Zwecke der Einwanderung, Grenzkontrolle, Sicherheit und Terrorismusbekämpfung oder andere als angemessen festgelegte Zwecke offenzulegen und zu verarbeiten. Manche Länder erteilen nur dann eine Reisegenehmigung, wenn Sie Ihre erweiterten Passagierdaten zur Verfügung stellen (beispielsweise Caricom API-Daten und US Secure Flight-Daten). Diese Voraussetzungen können je nach Ihrem Bestimmungsort verschieden sein und wir empfehlen Ihnen, dies im Einzelfall zu prüfen. Auch wenn es nicht verpflichtend ist, unterstützen wir Sie gerne.</p>
      <p><strong>Schutz Ihrer personenbezogenen Daten</strong></p>
      <p>Wir wissen, wie wichtig es ist, Ihre personenbezogenen Daten zu schützen und entsprechend sicher zu verwalten. Wir sehen unsere Verantwortung darin geeignete Maßnahmen zu treffen, um zu helfen, Ihre personenbezogenen Daten vor zufälligem Verlust und vor unbefugtem Zugriff, Nutzung, Änderung und Offenlegung zu schützen. Die Sicherheit Ihrer Daten können wir allein jedoch nicht gewährleisten, das hängt auch von Ihnen ab. Stellen Sie z.B. sicher, dass Sie Ihr Passwort, das wir Ihnen für den Zugang zu bestimmten Dienstleistungen gegeben haben bzw. das Sie sich ausgewählt haben, geheim halten.</p>
      <p>Ihre personenbezogenen Daten, können u. U. auch an einen Bestimmungsort außerhalb des Europäischen Wirtschaftsraumes (EWR) übermittelt und dort gespeichert werden. Sie dürfen auch von außerhalb des EWR operierenden Unternehmen verarbeiten werden, die für uns oder einen unserer Handelspartner arbeiten. Wir treffen geeignete Schutzvorkehrungen, um sicherzustellen, dass Ihre personenbezogenen Daten angemessen geschützt bleiben und dass sie in Übereinstimmung mit diesen Datenschutzhinweisen behandelt werden.</p>
      <p><strong>Aufbewahrung Ihrer Daten</strong></p>
      <p>Wir speichern Ihre personenbezogenen Daten nur so lange, wie es für die in diesen Datenschutzhinweisen dargelegten Zwecken notwendig ist und/oder, um gesetzlichen Aufbewahrungspflichten zu entsprechen. Nach diesem Zeitraum löschen wir personenbezogene Daten auf sichere Weise. Für analytische, historische oder andere legitime Geschäftszwecke anonymisieren wir die Daten im Rahmen der Vorschriften.</p>
      <p><strong>Kekse und technologische Dateien</strong></p>
      <p>Sog. „Cookies&quot; (= Kekse) sind kleine Datendateien, die es einer Webseite erlauben, eine Reihe von Daten auf Ihrem Desktop-Computer, Laptop oder Mobilgerät zu erheben und zu speichern. Cookies helfen, wichtige Features und Funktionalitäten digital bereitzustellen und wir benutzen sie, um Ihr Kundenerlebnis zu verbessern. Beachten Sie bitte auch unseren separaten Cookie-Hinweis.</p>
      <p><strong>Links zu anderen Webseiten</strong></p>
      <p>Unsere Webseite oder mobile App können Verlinkungen zu Webseiten anderer Organisationen enthalten, die ihre eigenen Datenschutzhinweise haben. Wir übernehmen keine Verantwortung oder Haftung für Webseiten anderer Organisationen.</p>
      <p><strong>Soziale Medien</strong></p>
      <p>Unsere Webseite oder mobile App enthalten sog. „Features&quot; sozialer Medien wie Facebook, Twitter, Instagram, TikTok etc., die ihre eigenen Datenschutzhinweise haben. <strong>Auf unseren Webseiten nutzen wir evtl. folgende Plugins:</strong> Facebook, Instagram, Twitter und TikTok. Wenn Sie nicht möchten, dass soziale Netzwerke über aktive Plugins Daten über Sie sammeln, können Sie in Ihren Browser-Einstellungen die Funktion &quot;Cookies von Drittanbietern blockieren&quot; wählen. Mit dieser Einstellung können allerdings seitenübergreifende Funktionseinbußen auftreten.</p>
      <p><em>Wenn diese Plugins aktiviert sind, baut Ihr Browser mit den Servern des jeweiligen sozialen Netzwerks eine direkte Verbindung auf, sobald Sie eine Webseite unseres Internetauftritts aufrufen. Der Inhalt des Plugins wird vom sozialen Netzwerk direkt an Ihren Browser übermittelt und von diesem in die Webseite eingebunden. Durch die Einbindung der Plugins erhält das soziale Netzwerk die Information, dass Sie die entsprechende Seite unseres Internetauftritts aufgerufen haben. Sind Sie bei dem sozialen Netzwerk eingeloggt, kann dieses den Besuch Ihrem Konto zuordnen. Wenn Sie mit den Plugins interagieren, zum Beispiel den Facebook &quot;Gefällt mir&quot; Button betätigen oder einen Kommentar abgeben, wird die entsprechende Information von Ihrem Browser direkt an das soziale Netzwerk übermittelt und dort gespeichert. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch soziale Netzwerke sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen der jeweiligen Netzwerke bzw. Webseiten. Die Links hierzu finden Sie weiter unten.</em></p>
      <p>Auch wenn Sie bei den sozialen Netzwerken nicht angemeldet sind, können von Webseiten mit aktiven „Social Plugins&quot; Daten an die Netzwerke gesendet werden. Durch einen aktiven Plugin wird bei jedem Aufruf der Webseite ein Cookie mit einer Kennung gesetzt. Da Ihr Browser dieses Cookie bei jeder Verbindung mit einem Netzwerk-Server prinzipiell ein Profil erstellen, welche Webseiten der zu der Kennung gehörende Anwender aufgerufen hat. Es wäre möglich, diese Kennung etwa beim späteren Anmelden bei dem sozialen Netzwerk auch wieder einer Person zuzuordnen.</p>
      <p>In unser Website <strong>sind Social Plugins (&quot;Plugins&quot;) des sozialen Netzwerkes facebook.com, welches von der Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA betrieben wird (&quot;Facebook&quot;), eingebunden</strong>. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von Facebook: <a href="http://www.facebook.com/policy.php">http://www.facebook.com/policy.php</a>. Sie können im Übrigen Facebook-Social-Plugins mit Add-ons für Ihren Browser blocken, z. B. mit dem &quot;Facebook Blocker&quot;.</p>
      <p>Einbindung von Twitter Plugins</p>
      <p>In unserer Website sind Social Plugins (&quot;Plugins&quot;) des sozialen Netzwerkes von Twitter, welches von der Twitter Inc. mit Sitz in 795 Folsom St., Suite 600, San Francisco, CA 94107, USA, betrieben wird („Twitter&quot;), eingebunden. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Twitter sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von Twitter: twitter.com/privacy.</p>
      <p>Einbindung von Pinterest Plugins</p>
      <p>Auf dieser Website sind des Weiteren Plugins des sozialen Netzwerks Pinterest Inc., 635 High Street, Palo Alto, CA, 94301, USA („Pinterest&quot;) integriert. Das Pinterest Plugin erkennen Sie an dem &quot;Pin it-Button&quot; auf unserer Seite. Wenn Sie den Pinterest &quot;Pin it-Button&quot; anklicken während Sie in Ihrem Pinterest-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Pinterest-Profil verlinken. Dadurch kann Pinterest den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Pinterest erhalten. Weitere Informationen können Sie der Datenschutzerklärung von Pinterest entnehmen: about.pinterest.com/de/privacy.</p>
      <p>Einbindung von Instagram Plugins</p>
      <p>Auf dieser Website sind des Weiteren Plugins des sozialen Netzwerks Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA („Instagram&quot;) integriert. Das Instagram Plugin erkennen Sie an dem &quot;Instagram - Button&quot; auf unserer Seite. Wenn Sie den &quot;Instagram - Button&quot; anklicken, während Sie in Ihrem Instagram - Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Instagram - Profil verlinken. Dadurch kann Instagram den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram erhalten. Weitere Informationen können Sie der Datenschutzerklärung von Instagram entnehmen: instagram.com/about/legal/privacy/.</p>
      <p><strong>Zugang und Aktualisierung Ihrer personenbezogenen Daten; Beschwerden</strong></p>
      <p>Sie haben das Recht, eine Kopie der personenbezogenen Daten, die wir über Sie speichern, anzufordern, obwohl Sie in der Regel auch die Möglichkeit haben sollten, die mit Ihrem Kunden-Account und Ihrer Buchung verbundenen Daten online einzusehen und zu aktualisieren. Sie können uns natürlich trotzdem schreiben und uns um eine Kopie sonstiger, über Sie gespeicherte personenbezogene Daten bitten. Sofern wir einen Datenzugriff bereitstellen können, werden wir dies kostenlos tun, es sei denn, es werden weitere Kopien angefordert. In diesem Fall dürfen wir eine angemessene, auf den Verwaltungskosten basierende Gebühr berechnen.</p>
      <p>Wir wollen sicherstellen, dass die personenbezogenen Daten, die wir über Sie speichern, korrekt und aktuell sind. Falls Informationen, die wir über Sie speichern, falsch sind, lassen Sie es uns bitte wissen. Sie können Ihre personenbezogenen Daten auch korrigieren oder löschen lassen, Einwände gegen die Verarbeitung Ihrer personenbezogenen Daten erheben und, soweit technisch umsetzbar, darum bitten, dass personenbezogene Daten, die Sie an uns übermittelt haben, an eine andere Organisation weitergegeben werden sollen. Wir werden Ihre Daten aktualisieren oder löschen, es sei denn, dass wir sie für legitime geschäftliche oder rechtliche Zwecke aufbewahren müssen.</p>
      <p>Sie können uns auch kontaktieren, wenn Sie sich darüber beschweren möchten, wie wir Ihre personenbezogenen Daten erheben, speichern und verwenden. Es ist unser Ziel, hinsichtlich Ihrer Beschwerden bestmöglich Abhilfe zu schaffen. Sollten Sie jedoch mit unserer Antwort nicht zufrieden sein, können Sie sich auch an die örtliche Datenschutzbehörde wenden.</p>
      <p>Beachten Sie bitte, dass wir berechtigt sind, Ihre Identität zu bestätigen, bevor wir Ihre Anfrage oder Beschwerde bearbeiten. Wir dürfen Sie auch um mehr Informationen bitten, die uns helfen sicherzustellen, dass Sie dazu berechtigt sind, diese Anfrage oder Beschwerde an uns zu richten, wenn Sie uns z. B. im Namen von jemand anderem kontaktieren.</p>
      <p><strong>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</strong></p>
      <p>Wir erheben und verwenden Ihre personenbezogenen Daten nur, wenn mindestens eine der folgenden Voraussetzungen zutrifft:</p>
      <ol>
      <li>Wir haben Ihre Einwilligung</li>
      </ol>
      <p>z. B. Kundenkonto: Sie geben uns die Erlaubnis, Ihre personenbezogenen Daten zu verarbeiten, wenn Sie sich für ein Kundenkonto anmelden.</p>
      <ol>
      <li>Es ist notwendig für einen Kontakt mit Ihnen oder auf Ihren Wunsch hin vor einem</li>
      </ol>
      <p>Vertragsabschluss entsprechende Schritte zu unternehmen</p>
      <p>z. B. Bereitstellung der von Ihnen gewünschten Produkte und Dienstleistungen: Wir müssen Ihre personenbezogenen Daten verarbeiten, damit wir in der Lage sind, Ihr Kundenkonto oder Ihre Buchung zu verwalten, Ihnen die Produkte und Dienstleistungen, die Sie kaufen wollen, anbieten zu können und Ihnen bei Bestellungen oder eventuellen Rückerstattungen zu helfen.</p>
      <ol>
      <li>Es ist notwendig, um eine rechtliche Verpflichtung einzuhalten</li>
      </ol>
      <p>z. B. Teilen personenbezogener Daten mit Behörden: Damit Sie reisen können, könnte es verpflichtend sein (gesetzlich vorgeschrieben durch Behörden am jeweiligen Ausgangs- und/oder Bestimmungsort), Ihre personenbezogenen Daten für Zwecke der Einwanderung, Grenzkontrolle, Sicherheit und Terrorismusbekämpfung oder andere von den Behörden als angemessen festgelegte Zwecke offenzulegen und zu verarbeiten.</p>
      <ol>
      <li>Es ist notwendig, um lebenswichtige Interessen von Ihnen oder anderen Personen zu schützen</li>
      </ol>
      <p>z. B. in einem Notfall: Ihre Versicherung, deren Agenten und medizinische Angestellte dürfen relevante personenbezogene Daten und besondere Arten personenbezogener Daten mit uns austauschen. Dies ist etwa der Fall, wenn wir oder die oben Erwähnten, in Ihrem Namen, im Interesse anderer Kunden oder in einem Notfall handeln müssen.</p>
      <ol>
      <li>Verarbeitung liegt im öffentlichen Interesse oder erfolgt in Ausübung öffentlicher Befugnis</li>
      </ol>
      <p>z. B. Sicherheitsmaßnahmen: Wir dürfen personenbezogene Daten verwenden, um Sicherheitsmaßnahmen durchzuführen oder auf Störungen oder andere ähnliche Vorkommnisse zu reagieren, darunter solche die von medizinischer und versicherungsbezogener Natur sind.</p>
      <ol>
      <li>Es liegt in unseren oder eines Dritten berechtigten Interessen sofern nicht Ihre eigenen</li>
      </ol>
      <p>Interessen oder Rechte überwiegen.</p>
      <p>z. B., um Ihr Reiseerlebnis zu personalisieren: Wir dürfen Ihre personenbezogenen Daten verwenden, um Ihre Interessen besser kennenzulernen, so dass wir versuchen können vorherzusehen, an welchen anderen Produkten, Dienstleistungen und Informationen Sie am meisten interessiert sein könnten. Dies ermöglicht es uns, unsere Kommunikationen maßzuschneidern, um sie für Sie relevanter zu gestalten.</p>
      <p>Sofern wir besondere Kategorien von personenbezogenen Daten verarbeiten müssen, beispielsweise Gesundheitsdaten aus medizinischen Gründen, erfolgt dies nur, wenn eine oder mehr zusätzliche Voraussetzungen zutreffen: wir haben Ihre ausdrückliche Zustimmung; es ist notwendig, Ihre lebenswichtigen Interessen oder solche einer anderen Person zu schützen und Sie sind körperlich oder rechtlich nicht in der Lage, die Zustimmung zu geben; es ist notwendig Rechtsansprüche zu begründen, geltend zu machen oder zu verteidigen; es ist notwendig aus Gründen eines erheblichen öffentlichen Interesses.</p>
      <p><strong>Änderungen unserer Datenschutzhinweise</strong></p>
      <p>Wir dürfen diesen Datenschutzhinweis jederzeit ändern, daher prüfen Sie ihn bitte regelmäßig auf Aktualisierungen. Falls die Änderungen bedeutsam sind, stellen wir eine deutlich erkennbare Nachricht bereit.</p>
      <p>Stand: Februar 2020</p>

      </section>
    </Layout>
  );
}

export default PrivacyPage;
